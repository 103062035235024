import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { CandidatService } from 'src/app/services/candidat.service';
import { Candidat } from './models/candidat.model';
import { PrimeNGConfig } from 'primeng/api';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	utilisateurConnecte: Candidat;
	responsive = 'menubar';

	constructor(private navigationService: NavigationService,
				private primeNgConfig: PrimeNGConfig) {
	}

	ngOnInit(): void {
		this.utilisateurConnecte = CandidatService.getCandidatConnecte();
		this.primeNgConfig.setTranslation({
			dayNames: ['Dimanche',
				'Lundi',
				'Mardi',
				'Mercredi',
				'Jeudi',
				'Vendredi',
				'Samedi'],
			dayNamesShort: ['Dim.',
				'Lun.',
				'Mar.',
				'Mer.',
				'Jeu.',
				'Ven.',
				'Sam.'],
			dayNamesMin: ['Di',
				'Lu',
				'Ma',
				'Me',
				'Je',
				'Ve',
				'Sa'],
			monthNames: ['Janvier',
				'Fevrier',
				'Mars',
				'Avril',
				'Mai',
				'Juin',
				'Juillet',
				'Août',
				'Septembre',
				'Octobre',
				'Novembre',
				'Decembre'],
			monthNamesShort: ['Janv.',
				'Fevr.',
				'Mars',
				'Avri.',
				'Mai',
				'Juin',
				'Juil.',
				'Août',
				'Sept.',
				'Octo.',
				'Novem.',
				'Decem.']
		});
	}

	goToIdentification(): void {
		this.navigationService.goTo('identification');
	}

	goToConnexionOuAccueil(): void {
		if (CandidatService.getCandidatConnecte()) {
			localStorage.removeItem('utilisateurConnecte');
			this.navigationService.goToHome();
		} else {
			this.navigationService.goTo('identification/connexion');
		}
		this.afficheResponsive();
	}

	goToInscriptionOuEspaceCandidat(): void {
		if (CandidatService.getCandidatConnecte()) {
			this.navigationService.goTo('espace-candidat');
		} else {
			this.navigationService.goTo('identification/inscription');
		}
		this.afficheResponsive();
	}

	isUtilisateurConnecte(): boolean {
		return CandidatService.getCandidatConnecte() !== null;
	}

	isIdentification(): boolean {
		return this.navigationService.getCurrentUrl().includes('/identification');
	}

	/**
	 * Afficher la bonne classe pour le css
	 */
	afficheResponsive(): void {
		if (this.responsive === 'menubar') {
			this.responsive += ' responsive';
		} else {
			this.responsive = 'menubar';
		}
	}

	isAccueil(): boolean {
		return this.navigationService.isHome();
	}
}
