import {Component, OnInit} from '@angular/core';
import {NavigationService} from 'src/app/services/navigation.service';
import {Candidat} from 'src/app/models/candidat.model';
import {CandidatService} from 'src/app/services/candidat.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'src/app/validators/custom-validators';
import {Router} from '@angular/router';
import {Message, MessageService} from 'primeng/api';
import {CandidatureService} from 'src/app/services/candidature.service';
import {PaysService} from '../../services/pays.service';
import {Pays} from '../../shared/models/pays.model';
import {finalize} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import {InvalidInputMessage} from '../../shared/models/invalid-input-message';
import {RequiredValidators} from '../../validators/required.validator';


@Component({
	selector: 'app-identification',
	templateUrl: './identification.component.html',
	styleUrls: ['./identification.component.scss']
})
export class IdentificationComponent implements OnInit {


	candidat: Candidat;
	maxYear = new Date().getFullYear() - 18;
	minYear = new Date().getFullYear() - 65;
	maxDate = new Date(this.maxYear, 11, 31);
	minDate = new Date(this.minYear, 0, 1);

	formulaireConnexion = new FormGroup({
		email: new FormControl('', [CustomValidators.notBlank, Validators.email]),
		password: new FormControl('', CustomValidators.notBlank),
	});

	formulaireCandidat = new FormGroup({
		id: new FormControl(''),
		nom: new FormControl('', [CustomValidators.notBlank, Validators.maxLength(50)]),
		prenoms: new FormControl('', [CustomValidators.notBlank, Validators.maxLength(100)]),
		pays: new FormControl(null, Validators.required),
		region: new FormControl(null, RequiredValidators.requiredIf(() => this.estBurkinaFaso())),
		province: new FormControl(null, RequiredValidators.requiredIf(() => this.estBurkinaFaso())),
		ville: new FormControl(null, RequiredValidators.requiredIf(() => this.estBurkinaFaso())),
		adresse: new FormControl(null, RequiredValidators.requiredIf(() => !this.estBurkinaFaso())),
		contact: new FormControl('', CustomValidators.notBlank),
		email: new FormControl('', [CustomValidators.notBlank, Validators.email, Validators.maxLength(100)]),
		dateNaissance: new FormControl('', [Validators.required, CustomValidators.validateAge(this.minDate, this.maxDate)]),
		lieuNaissance: new FormControl('', CustomValidators.notBlank),
		sexe: new FormControl('', Validators.required),
		password: new FormControl('', [CustomValidators.notBlank, Validators.minLength(8), Validators.maxLength(30)
		]),
		repassword: new FormControl('', [CustomValidators.notBlank, Validators.minLength(8), CustomValidators.matchValues('password'), Validators.maxLength(30)])
	});
	sexes = [
		{
			code: 'HOMME',
			designation: 'Homme'
		},
		{
			code: 'FEMME',
			designation: 'Femme'
		}
	];
	code: string;
	loading = false;
	index = 0;
	pays: Pays[] = [];
	messages: Message[];
	submittedConnexion = false;
	submittedInscription = false;
	invalidMessage = InvalidInputMessage.INVALID;
	requiredMessage = InvalidInputMessage.REQUIRED;
	dateInvalideMessage = 'Votre âge doit être compris entre 18 et 65 ans';
	readonly CODE_BURKINA_FASO = 'BF';

	constructor(private navigationService: NavigationService,
				private candidatService: CandidatService,
				private candidatureService: CandidatureService,
				private messageService: MessageService,
				private router: Router,
				private paysService: PaysService) {
	}

	ngOnInit(): void {
		const url = this.router.url;
		this.code = url.split('/').pop();
		if (url.includes('activer') && this.code) {
			this.candidatService.activer(this.code).subscribe({
				next: () => {
					this.navigationService.goTo('connexion');
				}
			});
		} else if (url.includes('connexion')) {
			this.index = 0;
		} else if (url.includes('inscription')) {
			this.index = 1;
		}
		this.recupererPays();
		this.formulaireCandidat.get('pays').valueChanges.subscribe(() => {
			const controlRegion = this.formulaireCandidat.get('region');
			const controlProvince = this.formulaireCandidat.get('province');
			const controlVille = this.formulaireCandidat.get('ville');
			controlRegion.updateValueAndValidity();
			controlRegion.reset();
			controlProvince.updateValueAndValidity();
			controlProvince.reset();
			controlVille.updateValueAndValidity();
			controlVille.reset();
			this.formulaireCandidat.get('adresse').updateValueAndValidity();
		});
	}

	creerCandidat(): void {
		this.submittedInscription = true;
		if (!this.formulaireCandidat.invalid) {
			const candidat: Candidat = this.formulaireCandidat.value;
			candidat.code = uuidv4();
			candidat.nom = this.formulaireCandidat.get('nom').value.trim();
			candidat.prenoms = this.formulaireCandidat.get('prenoms').value.trim();
			candidat.email = this.formulaireCandidat.get('email').value.trim();
			candidat.lieuNaissance = this.formulaireCandidat.get('lieuNaissance').value.trim();
			candidat.dateNaissance = this.formulaireCandidat.get('dateNaissance').value.replaceAll(' / ', '-');

			this.loading = true;
			this.messageService.clear('erreur');
			this.candidatService.creer(candidat).pipe(
				finalize(() => {
					this.loading = false;
				})
			).subscribe({
				error: (error) => {
					this.messageService.add({
						severity: 'error',
						summary: error.error.code,
						detail: error.error.message,
						key: 'erreur'
					});
				},
				complete: () => {
					this.submittedInscription = false;
					this.notifierInscription(candidat.email);
					setTimeout(() => window.location.reload(), 3000);
				}
			});
		}
	}

	connexion(): void {
		this.submittedConnexion = true;
		if (!this.formulaireConnexion.invalid) {
			this.loading = true;
			this.candidatService.rechercher(this.formulaireConnexion.get('email').value, this.formulaireConnexion.get('password').value)
				.subscribe({
					next: candidat => {
						CandidatService.updateUtilisateurConnecte(candidat);
					},
					error: erreur => {
						this.loading = false;
						this.notifierErreur();
					},
					complete: () => {
						this.notifierAuthentificationReussie();
						this.loading = false;
						setTimeout(() => {
							const referenceOffre = localStorage.getItem('offre');
							const candidatConnecte = CandidatService.getCandidatConnecte();
							if (referenceOffre) {
								this.isCandidatureExiste(referenceOffre, candidatConnecte.id);
							} else {
								this.navigationService.goToHome();
							}
						}, 2000);
					}
				});
		}
	}

	notifierInscription(email: string): void {
		this.messageService.add(
			{
				severity: 'success',
				summary: 'Inscription réussie',
				detail: 'Un mail de confirmation a été envoyé à l\'adresse ' + email,
				key: 'information'
			}
		);
	}

	notifierAuthentificationReussie(): void {
		this.messageService.add(
			{
				severity: 'success',
				summary: 'Authentification réussie',
				detail: 'Redirection en cours...',
				key: 'information'
			}
		);
	}

	notifierErreur(): void {
		this.messageService.add(
			{
				severity: 'error',
				summary: 'Authentification échouée',
				detail: 'Veuillez vérifier vos accès ou activez votre compte avec le lien d\'activation',
				key: 'information'
			}
		);
	}

	reinitialiserFormulaire(): void {
		this.formulaireCandidat.reset();
		this.formulaireConnexion.reset();
	}

	plageAnnees(): string {
		return this.minYear.toString() + ':' + this.maxYear.toString();
	}

	isCandidatureExiste(reference: string, idCandidat: number): void {
		this.candidatureService.isCandidatureExiste(reference, idCandidat).subscribe({
			next: (data) => {
				this.navigationService.goTo((data ? 'detail-offre' : 'candidature') + '/' + reference);
				localStorage.removeItem('offre');
			}
		});
	}

	/**
	 * Récupère les pays
	 */
	recupererPays(): void {
		this.paysService.lister().subscribe({
			next: (pays) => {
				this.pays = pays;
			}
		});
	}

	isInvalide(field: string): boolean {
		return this.submittedInscription && this.formulaireCandidat.get(field).invalid;
	}

	isNonRenseigne(field: string): boolean {
		return this.submittedInscription && !this.formulaireCandidat.get(field).value?.length;
	}

	/**
	 * Permet de dire si le pays selectionné est le burkina faso
	 * @return true si le pays est le burkina faso sinon false
	 */
	estBurkinaFaso(): boolean {
		return this.formulaireCandidat.get('pays').value?.code === this.CODE_BURKINA_FASO;
	}
}
