import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Pays} from '../shared/models/pays.model';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PaysService {
	private url = 'api/pays';

	constructor(private httpClient: HttpClient) {
	}

	/**
	 * Liste l'ensemble des pays disponible
	 */
	lister(): Observable<Pays[]> {
		return this.httpClient.get<Pays[]>(this.url);
	}
}
