import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CandidatureComponent } from 'src/app/modules/candidature/candidature.component';

const routes: Routes = [
	{
		path: '', component: CandidatureComponent,
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CandidatureRoutingModule {
}
