<div class="container-fluid justify-content-center p-0 mx-0">

	<footer>
		<hr class="line">
		<div class="row  mb-0 mt-5 mx-4 row-2 justify-content-xl-around justify-content-sm-between">
			<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 pt-4 order-1">
				<ul class="list-unstyled">
					<li class="mt-md-0 mt-4"><strong>NOS SOLUTIONS</strong></li>
					<li><a href="#!" class="text-reset">ABJobs</a></li>
					<li><a href="#!" class="text-reset">ABCash</a></li>
					<li><a href="#!" class="text-reset">ABMeters</a></li>
				</ul>
			</div>
<!--			<div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 pt-4 order-2">-->
<!--				<ul class="list-unstyled">-->
<!--					<li class="mt-md-0 mt-4"><strong>A DETERMINER OU  SUPPRIMER</strong></li>-->
<!--				</ul>-->
<!--			</div>-->
			<div class="col-xl-auto col-lg-6  col-md-6 pt-4 col-sm-6 my-sm-0 order-md-3 order-sm-1 d-flex">
				<ul class="list-unstyled">
					<li class="mt-md-0 mt-4"><strong>NOS CONTACTS</strong></li>
					<li><em class="fa fa-home me-3"></em> Abidjan Cocody, En face de la
						<br> pharmacie Sainte Famille
						<br> Immeuble FACT IMMOBILIER
						<br> (2ème étage, Portes B1, B2, B6)</li>
					<li><em class="fa fa-envelope me-3"></em>
						info@example.com
					</li>
					<li><em class="fa fa-phone me-3"></em> + 01 234 567 88</li>
				</ul>
			</div>
		</div>
		<hr class="line">
		<div class="row text-center py-3 author">
			<div class="col">
				<p class="text-muted">
					<strong>Tous droits reservés EBURTIS SARL</strong>
				</p>
			</div>
		</div>
	</footer>
</div>
