import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IdentificationComponent } from 'src/app/modules/identification/identification.component';

const routes: Routes = [
	{
		path: '',
		component: IdentificationComponent,
		children: [
			{
				path: 'activer/:id',
				component: IdentificationComponent,
			},
			{
				path: 'connexion',
				component: IdentificationComponent,
			},
			{
				path: 'inscription',
				component: IdentificationComponent,
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class IdentificationRoutingModule {
}
