import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class OffreEmploiService {

	private url = '/api/offre-emploi';

	constructor(private http: HttpClient) {
	}

	lister(): Observable<any> {
			return this.http.get<any>(this.url + '/lister');
	}

	rechercher(reference: string): Observable<any> {
		const params = new HttpParams().append('reference', reference);
		return this.http.get<any>(this.url, {params: params});
	}
}
