import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: '',
				redirectTo: '/accueil',
				pathMatch: 'full'
			},
			{
				path: 'accueil',
				loadChildren: () => import('./modules/accueil/accueil.module').then(m => m.AccueilModule)
			},
			{
				path: 'detail-offre/:id',
				loadChildren: () => import('./modules/detail-offre/detail-offre.module').then(m => m.DetailOffreModule)
			},
			{
				path: 'identification',
				loadChildren: () => import('./modules/identification/identification.module').then(m => m.IdentificationModule)
			},
			{
				path: 'candidature/:id',
				loadChildren: () => import('./modules/candidature/candidature.module').then(m => m.CandidatureModule)
			},
			{
				path: 'espace-candidat',
				loadChildren: () => import('./modules/espace-candidat/espace-candidat.module').then(m => m.EspaceCandidatModule)
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
