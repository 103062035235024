import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItem, MessageService} from 'primeng/api';
import {OffreEmploiService} from 'src/app/services/offre-emploi.service';
import {Candidature} from 'src/app/models/candidature.model';
import {CandidatService} from 'src/app/services/candidat.service';
import {CandidatureService} from 'src/app/services/candidature.service';
import {NavigationService} from 'src/app/services/navigation.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Candidat} from 'src/app/models/candidat.model';

@Component({
	selector: 'app-candidature',
	templateUrl: './candidature.component.html',
	styleUrls: ['./candidature.component.scss']
})
export class CandidatureComponent implements OnInit {

	private id: number;
	offreEmploi: any;
	etapes: MenuItem[];
	rubriques: any[] = [];
	criteres: any[] = [];
	index = 0;
	reference: string;
	candidature: Candidature;
	detailCandidatures: any[] = [];
	formulaireCandidature = new FormGroup({});
	isDejaPostule = false;
	candidatConnecte: Candidat;
	loading = false;
	submitted = false;

	constructor(private router: Router,
				private navigationService: NavigationService,
				private offreEmploiService: OffreEmploiService,
				private candidatureService: CandidatureService,
				private messageService: MessageService) {
	}

	ngOnInit(): void {
		const url = this.router.url;
		this.reference = url.split('/').pop();

		this.candidatConnecte = CandidatService.getCandidatConnecte();
		if (!this.candidatConnecte) {
			localStorage.setItem('offre', this.reference);
			this.navigationService.goTo('identification');
		}

		if (this.reference && this.candidatConnecte) {
			this.isCandidatureExiste();
		}

		this.rechercherOffre();
	}

	suivant(): void {
		this.index += 1;
	}

	precedent(): void {
		if (this.index > 0) {
			this.index -= 1;
		}
	}

	rechercherOffre(): void {
		this.offreEmploiService.rechercher(this.reference).subscribe({
			next: (data) => {
				this.offreEmploi = data;
				this.etapes = this.offreEmploi.questionnaire.rubriques.map(rubrique => {
					return {label: rubrique.designation};
				});

				this.rubriques = this.offreEmploi.questionnaire.rubriques;

				this.candidature = new Candidature();
				this.rubriques.forEach(rubrique => {
					rubrique.criteres.forEach(critere => {
						this.detailCandidatures.push(
							{
								critere: critere,
								valeur: undefined,
								valeurCritere: undefined
							}
						);

						this.formulaireCandidature.addControl(critere.code, new FormControl('', critere.obligatoire ? Validators.required : null));
					});
				});
			}
		});
	}

	getDetailCandidature(critere: any): any {
		return this.detailCandidatures.find(detailCandidature => detailCandidature.critere.code === critere.code);
	}

	postuler(): void {
		this.submitted = true;
		if (!this.formulaireCandidature.invalid) {
			this.loading = true;
			this.detailCandidatures.forEach(detailCandidature => {
				const critere = detailCandidature.critere;
				const formControl = this.formulaireCandidature.get(critere.code);
				if (['SAISIE_LIBRE', 'SELECTION_MULTIPLE'].includes(critere.typeCritere.code)) {
					this.getDetailCandidature(critere).valeur = formControl.value?.trim().length ? formControl.value : null;
				} else {
					this.getDetailCandidature(critere).valeurCritere = formControl.value === '' ? null : formControl.value;
				}
			});

			this.candidature.candidat = CandidatService.getCandidatConnecte();
			this.candidature.detailCandidatures = this.detailCandidatures;
			this.candidature.offreEmploi = this.offreEmploi;

			this.candidatureService.creer(this.candidature).subscribe({
				next: () => {
					this.notifierEnregistrement();
					setTimeout(() => this.navigationService.goToHome(), 2000);
				}
			});
		} else {
			this.hasInvalidCritere();
		}
	}

	onDetailCandidatureChange($event: any, critere: any): void {
		this.formulaireCandidature.get(this.getDetailCandidature(critere).critere.code).setValue($event);
		this.hasInvalidCritere();
	}

	notifierEnregistrement(): void {
		this.messageService.add(
			{
				severity: 'success',
				summary: 'Candidature envoyé',
				detail: 'Votre candidature sera analysée par les recruteurs'
			}
		);
	}

	isCandidatureExiste(): void {
		this.candidatureService.isCandidatureExiste(this.reference, this.candidatConnecte.id).subscribe({
			next: (data) => {
				if (data) {
					this.navigationService.goTo('detail-offre/' + this.reference);
				}
			}
		});
	}

	hasInvalidCritere() {
		Object.entries(this.formulaireCandidature.controls).forEach(entrie => {
			const codeCritere = entrie[0];
			const formCritere = entrie[1];
			if (this.submitted && formCritere.status === 'INVALID') {
				const rubrique = this.rubriques.find(rubrique => rubrique.criteres.map(critere => critere.code).includes(codeCritere));
				const onglet = this.etapes.find(etape => etape.label === rubrique.designation);
				onglet.styleClass = 'invalid-critere';
				onglet.icon = 'fa fa-warning';
			}
		})
	}

	getEtapes(): MenuItem[] {
		this.hasInvalidCritere()
		return this.etapes;
	}
}
