<span class="text-500" [ngClass]="{'label-required': critere.obligatoire}">{{ critere.designation }}</span>
<div class="row ml-0">
	<input pInputText
		   [(ngModel)]="valeurSaisie"
		   (input)="notifierChangement($event)"
		   class="saisie-input"
		   id="re-password"
		   type="text">
	<app-invalid-input-message mode="text" [show]="submitted && critere.obligatoire && !valeurSaisie?.trim()?.length"></app-invalid-input-message>
</div>
