import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccueilComponent } from 'src/app/modules/accueil/accueil.component';

const routes: Routes = [
	{
		path: '', component: AccueilComponent,
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AccueilRoutingModule {
}
