import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CandidatService} from '../../../../services/candidat.service';
import {NavigationService} from '../../../../services/navigation.service';
import {v4 as uuidv4} from 'uuid';
import {ConfirmationService} from 'primeng/api';


@Component({
	selector: 'app-photo',
	templateUrl: './photo.component.html',
	styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {
	uploadedFiles: any[] = [];
	loading = false;
	candidat = CandidatService.getCandidatConnecte();
	@Output() notifierUpload: EventEmitter<void> = new EventEmitter<void>();
	@Output() notifierSuppression: EventEmitter<void> = new EventEmitter<void>();
	@Output() notifierErreurSuppression: EventEmitter<string> = new EventEmitter<string>();
	@Input() submitted: boolean;
	hostname: string;

	constructor(private candidatService: CandidatService,
				private  navigationService: NavigationService,
				private confirmationService: ConfirmationService) {
	}

	ngOnInit(): void {
		this.hostname = this.navigationService.getHostname();
		if (this.hostname === 'vps10010.winihost.com') {
			this.hostname = 'recrutement.stone.ci';
		} else if (this.hostname === 'localhost') {
			this.hostname = 'localhost:9000';
		}
	}

	onSelect(): void {
		this.loading = true;
	}

	upload(event): void {
		for (const file of event.files) {
			const blob = file.slice(0, file.size, 'image/png');
			const newFile = new File([blob], (uuidv4().replaceAll('-', '') + '.' + file.name.split('.').pop()), {type: file.type});

			this.candidatService.uploadPhoto(this.candidat?.id, newFile).subscribe({
				next: (data) => {
					this.loading = false;
					CandidatService.updateUtilisateurConnecte(data);
					this.candidat = data;
					this.notifierUpload.emit();
				},
				error: (err) => {

				}
			});
		}
		this.loading = true;
	}

	confirmerSuppressionPhoto(): void {
		this.confirmationService.confirm({
			message: 'Voulez-vous vraiment supprimer cette photo ?',
			accept: () => {
				this.candidatService.deletePhoto(this.candidat.id).subscribe({
					next: (data) => {
						CandidatService.updateUtilisateurConnecte(data);
						this.candidat = data;
						this.notifierSuppression.emit();
					},
					error: (err) => {
						this.notifierErreurSuppression.emit(err.error.message);
					}
				});
			}
		});
	}
}
