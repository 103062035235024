import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-selection-unique',
	templateUrl: './selection-unique.component.html',
	styleUrls: ['./selection-unique.component.scss']
})
export class SelectionUniqueComponent implements OnInit {

	@Input() critere: any;
	@Input() valeurSelectionne: any;
	@Output() detailCandidatureChange: EventEmitter<any> = new EventEmitter<any>();
	@Input() submitted: boolean;

	constructor() {
	}

	ngOnInit(): void {
	}

	notifierChangement($event: any): void {
		this.detailCandidatureChange.emit($event.value);
	}
}
