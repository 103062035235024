<p-toast></p-toast>
<div class="candidature">
	<div class="header">
		<div class="row">
			<span class="block text-500 text-center">{{offreEmploi?.entreprise?.designation}} recrute</span>
		</div>
		<div class="row offre-titre">
			<span class="block font-bold text-400 text-center">{{offreEmploi?.titre}}</span>
		</div>
		<div class="row">
			<span class="block text-500 text-center mt-2 mb-5">{{offreEmploi?.typeContrat?.designation}} - {{offreEmploi?.localisation}}</span>
		</div>
	</div>

	<div class="container col-sm-12">
		<div class="row mt-5">
			<p-steps [model]="getEtapes()" [(activeIndex)]="index"></p-steps>

			<div class="critere">
				<div class="col-sm mt-4" *ngFor="let critere of rubriques[index]?.criteres">
					<app-selection-multiple *ngIf="critere?.typeCritere?.code === 'SELECTION_MULTIPLE'"
											[critere]="critere"
											[submitted]="submitted"
											[valeurSelectionne]="formulaireCandidature.get(critere.code).value"
											(detailCandidatureChange)="onDetailCandidatureChange($event, critere)">
					</app-selection-multiple>

					<app-selection-binaire *ngIf="critere?.typeCritere?.code === 'BINAIRE'"
										   [critere]="critere"
										   [submitted]="submitted"
										   [valeurSelectionne]="formulaireCandidature.get(critere.code).value"
										   (detailCandidatureChange)="onDetailCandidatureChange($event, critere)">
					</app-selection-binaire>

					<app-saisie *ngIf="critere?.typeCritere?.code === 'SAISIE_LIBRE'"
								[critere]="critere"
								[submitted]="submitted"
								[valeurSaisie]="formulaireCandidature.get(critere.code).value"
								(detailCandidatureChange)="onDetailCandidatureChange($event, critere)">
					</app-saisie>

					<app-selection-unique *ngIf="critere?.typeCritere?.code === 'SELECTION_UNIQUE'"
										  [critere]="critere"
										  [submitted]="submitted"
										  [valeurSelectionne]="formulaireCandidature.get(critere.code).value"
										  (detailCandidatureChange)="onDetailCandidatureChange($event, critere)">
					</app-selection-unique>
				</div>
			</div>

			<div class="col-8 mx-auto">
				<div class="row mx-auto">
					<div class="col-6 mx-auto mt-1 text-left">
						<button *ngIf="index > 0" pButton class="mt-5 btn-primary" (click)="precedent()" [disabled]="loading">
							<em class="mr-1 fa fa-arrow-circle-left"></em>
							Précédent
						</button>
					</div>
					<div *ngIf="index < rubriques.length - 1" class="col-6 mx-auto mt-1 text-right">
						<button pButton class="mt-5 btn-primary" (click)="suivant()" [disabled]="loading">
							Suivant
							<em class="ml-1 fa fa-arrow-circle-right"></em>
						</button>
					</div>
					<div *ngIf="!loading && index === rubriques.length - 1" class="col-6 mx-auto mt-1 text-right">
						<button pButton class="mt-5 btn-primary" (click)="postuler()">
							Postuler
							<em class="ml-1 fa fa-send-o"></em>
						</button>
					</div>
					<div *ngIf="loading" class="col-6 mx-auto mt-1 text-right">
						<p-progressSpinner></p-progressSpinner>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
<app-footer></app-footer>
