<span class="text-500" [ngClass]="{'label-required': critere.obligatoire}">{{ critere.designation }}</span>
<div class="row">
	<p-dropdown [options]="critere.valeurCriteres"
					[(ngModel)]="valeurSelectionne"
				placeholder="Sélectionner une valeur"
				emptyFilterMessage="Aucune valeur trouvée"
				(onChange)="notifierChangement($event)"
				optionLabel="valeur">
	</p-dropdown>
	<app-invalid-input-message mode="text" [show]="submitted && critere.obligatoire && !valeurSelectionne"></app-invalid-input-message>
</div>
