<div class="row">
	<div class="col-6 input-gauche text-center">
		<img class="img" *ngIf="candidat?.photo" src="http://{{ hostname }}/fichiers/profil/{{candidat?.photo}}" alt="photo de profil" height="100">
	</div>
	<div class="col-6 input-droit">
		<div class="row">
			<div class="col-10 text-right pr-0">
				<p-fileUpload name="photo"
							  url="/api/candidats/upload-photo?idCandidat={{ candidat?.id }}"
							  accept="image/*"
							  chooseLabel="{{ candidat?.photo ? 'Changer la photo' : 'Choisir une photo' }}"
							  mode="basic"
							  method="post"
							  [customUpload]="true"
							  [auto]="true"
							  (onSelect)="onSelect()"
							  (uploadHandler)="upload($event)"
							  *ngIf="!loading"
							  [showCancelButton]="false"
							  [showUploadButton]="false">
				</p-fileUpload>
			</div>
			<div class="col-2">
				<em class="btn btn-danger fa fa-trash" (click)="confirmerSuppressionPhoto()" *ngIf="candidat?.photo && !loading"></em>
			</div>
		</div>

		<p-progressSpinner *ngIf="loading"></p-progressSpinner>
	</div>
</div>
