import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-selection-binaire',
	templateUrl: './selection-binaire.component.html',
	styleUrls: ['./selection-binaire.component.scss']
})
export class SelectionBinaireComponent implements OnInit {

	@Input() critere: any;
	@Input() valeurSelectionne: string;
	@Output() detailCandidatureChange: EventEmitter<any> = new EventEmitter<any>();
	@Input() submitted: boolean;

	constructor() {
	}

	ngOnInit(): void {
	}

	notifierChangement($event: any): void {
		this.detailCandidatureChange.emit($event.option);
	}
}
