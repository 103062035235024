<div class="identification">
	<p-toast key="information" position="top-center"></p-toast>
	<p-messages key="erreur" [(value)]="messages"></p-messages>
	<p-tabView (onChange)="reinitialiserFormulaire()">
		<p-tabPanel header="Connexion" [selected]="index === 0">
			<div class="container col-sm-6 mt-3">
				<form [formGroup]="formulaireConnexion">
					<div class="row">
						<div class="col-sm mx-auto">
							<label class="label-required input-label" for="nom">Email</label>
							<input pInputText
								   formControlName="email"
								   class="custom-input"
								   placeholder="Email"
								   id="username"
								   type="text">
							<app-invalid-input-message mode="text"
													   [show]="submittedConnexion && formulaireConnexion.get('email').invalid"></app-invalid-input-message>
						</div>
					</div>
					<div class="row">
						<div class="col-sm mx-auto mt-5">
							<label class="label-required input-label" for="password">Mot de passe</label>
							<p-password formControlName="password"
										[toggleMask]="true"
										placeholder="****************"
										[feedback]="false"
										id="password"
										[minlength]="8"
										maxlength="30"
										class="custom-input connexion">
							</p-password>
							<app-invalid-input-message mode="text"
													   message="Veuillez renseigner correctement ce champ (au moins 8 caractères)"
													   [show]="submittedConnexion && formulaireConnexion.get('password').invalid">
							</app-invalid-input-message>
						</div>
						<div class="row">
							<div *ngIf="!loading" class="col-12 mx-auto mt-1 text-right">
								<button pButton class="mt-5 btn-primary" (click)="connexion()">
									<em class="mr-1 fa fa-sign-in"></em>
									Connexion
								</button>
							</div>
							<div *ngIf="loading" class="col-12 mx-auto mt-1 text-right">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</div>
				</form>
			</div>
		</p-tabPanel>

		<p-tabPanel header="Inscription" [selected]="index === 1">
			<div class="container identification form-inline col-12">
				<form [formGroup]="formulaireCandidat">
					<div class="row mt-8">
						<div class="input-gauche">
							<span class=" input-container">
								<label class="label-required input-label" for="nom">Nom</label>
							<input pInputText
								   formControlName="nom"
								   class="form-control nom-prenom custom-input"
								   id="nom"
								   placeholder="Nom"
								   type="text" maxlength="50">
							</span>
							<app-invalid-input-message mode="text"
													   [show]="isInvalide('nom')"></app-invalid-input-message>
						</div>
						<div class="input-droit">
						<span class=" input-container">
							<label class="label-required input-label" for="prenoms">Prénoms</label>
							<input pInputText
								   formControlName="prenoms"
								   maxLength="100"
								   class="form-control nom-prenom custom-input"
								   id="prenoms"
								   placeholder="Prénoms"
								   type="text"
								   maxlength="100">
							</span>
							<app-invalid-input-message mode="text"
													   [show]="isInvalide('prenoms')"></app-invalid-input-message>
						</div>
					</div>

					<div class="mt-2 row">
						<div class="input-gauche">
							<div class="input-gauche-pays-contact">
								<label class="label-required input-label" for="sexe">Sexe</label>
								<p-selectButton [options]="sexes"
												id="sexe"
												optionLabel="designation"
												formControlName="sexe"
												optionValue="code"
												styleClass="button-select">
								</p-selectButton>
								<app-invalid-input-message mode="text"
														   [show]="isInvalide('sexe')"></app-invalid-input-message>
							</div>
							<div class="input-droit-pays-contact">
								<label class="label-required input-label" for="contact">Date de naissance</label>
								<p-inputMask pInputText
											 class="w-100 custom-input"
											 id="dateNaissance"
											 style="width: 50%"
											 type="text"
											 mask="99 / 99 / 9999"
											 placeholder="jj / mm / aaaa"
											 formControlName="dateNaissance">
								</p-inputMask>
								<em pTooltip="Votre âge doit être compris entre 18 et 65 ans" tooltipPosition="top"
									class="ml-2 fa fa-question-circle"></em>
								<app-invalid-input-message mode="text"
														   [message]="!formulaireCandidat.get('dateNaissance').value?.length ? requiredMessage : dateInvalideMessage"
														   [show]="isInvalide('dateNaissance')">
								</app-invalid-input-message>
							</div>
						</div>
						<div class="input-droit">
						<span class="input-container">
							<label class="label-lieu-naissance label-required input-label" for="lieu-naissance">Lieu de naissance</label>
							<input pInputText
								   formControlName="lieuNaissance"
								   class="lieu-naissance custom-input"
								   maxLength="100"
								   placeholder="Lieu de naissance"
								   id="lieu-naissance"
								   type="email">
							<em class="ml-2 fa fa-question-circle"></em>
							</span>
							<app-invalid-input-message mode="text"
													   [show]="isInvalide('lieuNaissance')"></app-invalid-input-message>
						</div>
					</div>

					<div class="row mt-3">
						<div class="input-gauche">
							<div class="input-gauche-pays-contact pays">
								<label class="row mt-1 label-required input-label" for="pays">Pays</label>
								<p-dropdown appendTo="body"
											[options]="pays"
											formControlName="pays"
											optionLabel="designation"
											[filter]="true"
											filterBy="designation"
											dataKey="code"
											class="pays"
											placeholder="Choisir l'indicatif du pays"
											id="pays"
											[autoDisplayFirst]="false"
											emptyFilterMessage="Aucun pays trouvé"
											emptyMessage="Aucun pays disponible"
								            #paysDropdown
								>
									<ng-template let-pays pTemplate="item">
										<div>
											<span [class]="'fi fi-'+ pays.code.toLowerCase()"></span>
											<span> {{pays.indicatif + ' ' + pays.designation}} </span>
										</div>
									</ng-template>
									<ng-template pTemplate="selectedItem">
										<div>
											<span [class]="'fi fi-'+ paysDropdown.value.code.toLowerCase()"></span>
											<span> {{paysDropdown.value.indicatif + ' ' + paysDropdown.value.designation}} </span>
										</div>
									</ng-template>
								</p-dropdown>
								<app-invalid-input-message mode="text"
														   [show]="isInvalide('pays')"></app-invalid-input-message>
							</div>
							<div class="input-droit-pays-contact contact-container">
								<label class=" row mt-1 label-required input-label" for="contact">Contact</label>
								<p-inputMask pInputText
											 class="w-100 custom-input"
											 id="contact"
											 type="text"
											 [mask]="paysDropdown.value ? paysDropdown.value.masqueNumeroTelephone : ''"
											 [placeholder]="paysDropdown.value ? paysDropdown.value.masqueNumeroTelephone : 'numero telephone'"
											 formControlName="contact">
								</p-inputMask>
								<app-invalid-input-message mode="text"
														   [show]="isInvalide('contact')"></app-invalid-input-message>
							</div>
						</div>
						<div class="input-droit">
							<label class="label-required label-email input-label" for="email">Email</label>
							<input pInputText
								   formControlName="email"
								   class="email custom-input"
								   id="email"
								   type="email"
								   placeholder="Email"
								   maxlength="100">
							<em class="ml-2 fa fa-question-circle"></em>
							<app-invalid-input-message mode="text" [show]="isInvalide('email')"
													   [message]="isNonRenseigne('email') ? requiredMessage : invalidMessage"></app-invalid-input-message>
						</div>
					</div>
					<div class="row mt-3">
						<div class="input-gauche">
							<div class="input-gauche-pays-contact pays">
								<label class="row mt-1 input-label" [ngClass]="{'label-required' : estBurkinaFaso()}"
									   for="region">Region</label>
								<p-dropdown appendTo="body"
											[disabled]="!paysDropdown.value || !estBurkinaFaso()"
											[options]="paysDropdown.value ? paysDropdown.value.regions: []"
											formControlName="region"
											optionLabel="designation"
											[filter]="true"
											filterBy="designation"
											dataKey="code"
											class="pays"
											placeholder="Choisir une region"
											id="region"
											[autoDisplayFirst]="false"
											emptyFilterMessage="Aucune region trouvé"
											emptyMessage="Aucune region disponible"
								            #regionDropdown>
								</p-dropdown>
								<em pTooltip="Vous pourrez remplir ce champ si vous êtes au Burkina Faso"
									tooltipPosition="top"
									class="ml-2 fa fa-question-circle"></em>
								<app-invalid-input-message mode="text"
														   [show]="isInvalide('region')"></app-invalid-input-message>
							</div>
							<div class="province input-droit-pays-contact pays">
								<label class="row mt-1 input-label" [ngClass]="{'label-required' : estBurkinaFaso()}" for="province">Province</label>
								<p-dropdown appendTo="body"
											[disabled]="!regionDropdown.value || !estBurkinaFaso()"
											[options]="regionDropdown.value ? regionDropdown.value.provinces: []"
											formControlName="province"
											optionLabel="designation"
											[filter]="true"
											filterBy="designation"
											dataKey="code"
											class="pays"
											placeholder="Choisir une province"
											id="province"
											[autoDisplayFirst]="false"
											emptyFilterMessage="Aucune province disponible trouvé"
											emptyMessage="Aucune province disponible"
								            #provinceDropdown>
								</p-dropdown>
								<em pTooltip="Vous pourrez remplir ce champ si vous êtes au Burkina Faso"
									tooltipPosition="top"
									class="ml-2 fa fa-question-circle"></em>
								<app-invalid-input-message mode="text"
														   [show]="isInvalide('province')"></app-invalid-input-message>
							</div>
						</div>
						<div class="input-droit">
							<div class="input-gauche-pays-contact">
								<label class="row mt-1 input-label" [ngClass]="{'label-required' : estBurkinaFaso()}"
									   for="ville">Ville</label>
								<p-dropdown appendTo="body"
											[disabled]="!provinceDropdown.value || !estBurkinaFaso()"
											[options]="provinceDropdown.value ? provinceDropdown.value.villes : []"
											formControlName="ville"
											optionLabel="designation"
											[filter]="true"
											filterBy="designation"
											dataKey="code"
											class="pays"
											placeholder="Choisir une ville"
											id="ville"
											[autoDisplayFirst]="false"
											emptyFilterMessage="Aucune ville disponible trouvé"
											emptyMessage="Aucune ville disponible"
								>
								</p-dropdown>
								<em pTooltip="Vous pourrez remplir ce champ si vous êtes au Burkina Faso"
									tooltipPosition="top"
									class="ml-2 fa fa-question-circle"></em>
								<app-invalid-input-message mode="text"
														   [show]="isInvalide('ville')"></app-invalid-input-message>
							</div>

							<div class="input-droit-pays-contact">
								<label class="input-label" for="adresse"
									   [ngClass]="{'label-required' : !estBurkinaFaso()}">Adresse complémentaire /
									Adresse</label>
								<input pInputText
									   formControlName="adresse"
									   class="adresse custom-input"
									   id="adresse"
									   type="text"
									   placeholder="Adresse complémentaire / Adresse"
									   maxlength="100">
								<app-invalid-input-message mode="text"
														   [show]="isInvalide('adresse')"></app-invalid-input-message>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="input-gauche">
							<label class="label-required input-label" for="password">Mot de passe</label>
							<p-password formControlName="password"
										[toggleMask]="true"
										promptLabel="8 caractères au minimum"
										placeholder="****************"
										id="input-password"
										weakLabel="Faible"
										mediumLabel="Moyen"
										strongLabel="Fort"
										[minlength]="8"
										maxlength="30"
										class="custom-input">
							</p-password>
							<em pTooltip="Le mot de passe doit avoir une taille d'au moins 8 caractères et contenir au moins une lettre majuscule, un chiffre "
								tooltipPosition="top" class="ml-2 fa fa-question-circle"></em>
							<app-invalid-input-message mode="text" [show]="isInvalide('password')"
													   [message]="isNonRenseigne('password') ? requiredMessage : invalidMessage"></app-invalid-input-message>
						</div>
						<div class="input-droit">
							<label class="label-required input-label" for="repassword">Saisir à nouveau le mot de
								passe</label>
							<p-password formControlName="repassword"
										[toggleMask]="true"
										promptLabel="Saisir le même mot de passe"
										placeholder="{{formulaireCandidat.get('password').value?.length >= 8 ? '****************' : 'Renseigner correctement le champ mot de passe'}}"
										weakLabel="Faible"
										id="repassword"
										mediumLabel="Moyen"
										strongLabel="Fort"
										[disabled]="formulaireCandidat.get('password').invalid"
										[minlength]="8"
										maxlength="30"
										class="custom-input">
							</p-password>
							<em pTooltip="Vous pourrez remplir ce champ si le champ mot de passe est renseigné et valide. Vous devez renseigner dans ce champ un mot de passe identique au précecent."
								tooltipPosition="top"
								class="ml-2 fa fa-question-circle"></em>
							<app-invalid-input-message mode="text" [show]="isInvalide('repassword')"
													   [message]="isNonRenseigne('repassword') ? requiredMessage : invalidMessage"></app-invalid-input-message>
						</div>
					</div>

					<div class="row mx-auto">
						<div *ngIf="!loading" class="col-12 mx-auto mt-1 text-right">
							<button pButton class="mt-5 btn-primary" (click)="creerCandidat()" type="submit">
								<em class="mr-1 fa fa-sign-in"></em>
								S'inscrire
							</button>
						</div>
						<div *ngIf="loading" class="col-12 mx-auto mt-1 text-right">
							<p-progressSpinner></p-progressSpinner>
						</div>
					</div>
				</form>
			</div>
		</p-tabPanel>
	</p-tabView>
</div>

<app-footer></app-footer>
