import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InvalidInputMessageComponent} from './components/invalid-input-message/invalid-input-message.component';
import {TooltipModule} from "primeng/tooltip";


@NgModule({
	declarations: [
		InvalidInputMessageComponent
	],
	exports: [
		InvalidInputMessageComponent
	],
	imports: [
		CommonModule,
		TooltipModule
	]
})
export class SharedModule {
}
