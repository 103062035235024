<span class="text-500" [ngClass]="{'label-required': critere.obligatoire}">{{ critere.designation }}</span>
<div class="row">
	<p-multiSelect [options]="critere.valeurCriteres"
				   [(ngModel)]="valeurCritereSelectionnes"
				   placeholder="Sélectionner une valeur"
				   selectedItemsLabel="{0} valeurs sélectionnées"
				   [maxSelectedLabels]="1"
				   (onChange)="notifierChangement($event)"
				   emptyFilterMessage="Aucune valeur trouvée"
				   optionLabel="valeur">
	</p-multiSelect>
	<app-invalid-input-message mode="text" [show]="submitted && critere.obligatoire && !valeurCritereSelectionnes?.length"></app-invalid-input-message>
</div>
