import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class TypeContratService {

	private url = '/api/type-contrat';

	constructor(private http: HttpClient) {
	}

	lister(): Observable<any> {
		return this.http.get<any>(this.url + '/lister');
	}
}
