import { NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';


@NgModule({
	declarations: [
		FooterComponent
	],
	exports: [
		FooterComponent
	],
	imports: []
})
export class SharedRecrutementModule {
}
