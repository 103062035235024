import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { IdentificationComponent } from 'src/app/modules/identification/identification.component';
import { IdentificationRoutingModule } from 'src/app/modules/identification/identification-routing.module';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SharedRecrutementModule } from 'src/app/shared/component/shared-recrutement.module';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { SharedModule } from 'src/app/shared/shared.module';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
	declarations: [
		IdentificationComponent
	],
	imports: [
		CommonModule,
		IdentificationRoutingModule,
		ButtonModule,
		TabViewModule,
		InputTextModule,
		InputMaskModule,
		CalendarModule,
		ReactiveFormsModule,
		SelectButtonModule,
		ProgressSpinnerModule,
		ToastModule,
		DropdownModule,
		MessagesModule,
		PasswordModule,
		SharedModule,
		SharedRecrutementModule,
		TooltipModule
	],
	providers: [
		MessageService
	]
})
export class IdentificationModule {
}
