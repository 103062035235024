<div class="row">
	<div class="col-6 input-gauche text-center">
		<a href="http://{{ hostname }}/fichiers/cv/{{ candidat?.cv }}" target="_blank" *ngIf="candidat?.cv">
			<em class="fa fa-file-pdf-o pdf"></em>
		</a>
	</div>
	<div class="col-6 input-droit">
		<div class="row">
			<div class="col-10 text-right pr-0">
				<p-fileUpload name="cv"
							  url="/api/candidats/upload-cv?idCandidat={{ candidat?.id }}"
							  accept="application/pdf"
							  chooseLabel="Sélectionner votre CV"
							  mode="basic"
							  method="post"
							  [customUpload]="true"
							  [auto]="true"
							  (onSelect)="onSelect()"
							  (uploadHandler)="upload($event)"
							  *ngIf="!loading"
							  [showCancelButton]="false"
							  [showUploadButton]="false">
				</p-fileUpload>
			</div>
			<div class="col-2 text-right">
				<em class="btn btn-danger fa fa-trash" (click)="confirmerSuppressionCv()"
					*ngIf="candidat?.cv && !loading"></em>
			</div>
		</div>

		<p-progressSpinner *ngIf="loading"></p-progressSpinner>
	</div>
</div>
