import {Component, Input, OnInit} from '@angular/core';
import {InvalidInputMessage} from "../../models/invalid-input-message";
import {InvalidInputMode} from "../../models/invalid-input-mode";
import {InvalidInputType} from "../../models/invalid-input-type";

@Component({
	selector: 'app-invalid-input-message',
	templateUrl: './invalid-input-message.component.html',
	styleUrls: ['./invalid-input-message.component.scss']
})
export class InvalidInputMessageComponent implements OnInit {

	@Input() type = InvalidInputType.REQUIRED;
	@Input() mode = InvalidInputMode.TEXT;
	@Input() message = InvalidInputMessage.REQUIRED;
	@Input() show = false;
	@Input() icon = 'fa fa-warning';

	readonly iconMode = InvalidInputMode.ICON;
	readonly textMode = InvalidInputMode.TEXT;

	constructor() {
	}

	ngOnInit(): void {
	}

}
