<div class="accueil container-fluid mt-5">
	<div class="search-zone container">
		<div class="row">
			<div class="col-sm mb-1">
				<p-multiSelect [options]="typeContrats"
							   [(ngModel)]="typeContratSelectionnes"
							   placeholder="Type de contrat"
							   selectedItemsLabel="{0} valeurs sélectionnées"
							   [maxSelectedLabels]="1"
							   emptyFilterMessage="Aucun type de contrat trouvé"
							   optionLabel="designation">
				</p-multiSelect>
			</div>
			<div class="col-sm mb-1">
				<span class="input-container">
					<input pInputText
						   [(ngModel)]="filtreEmploi"
						   class="custom-input"
						   id="emploi"
						   placeholder="Emploi"
						   type="text">
				</span>
				<!--				<p-multiSelect [options]="metiers"-->
				<!--							   [(ngModel)]="metierSelectionnes"-->
				<!--							   placeholder="Secteur d'activités"-->
				<!--							   selectedItemsLabel="{0} valeurs sélectionnées"-->
				<!--							   [maxSelectedLabels]="1"-->
				<!--							   emptyFilterMessage="Aucun secteur d'activité trouvé"-->
				<!--							   optionLabel="designation">-->
				<!--				</p-multiSelect>-->
			</div>
			<div class="col-sm">
				<span class="input-container">
					<input pInputText
						   [(ngModel)]="filtreVille"
						   class="custom-input"
						   id="ville"
						   placeholder="Ville"
						   type="text">
				</span>
			</div>
		</div>
	</div>

	<div class="offre-zone container">
		<div class="row col-12">
			<p-dataView [value]="filtrerOffres()"
						layout="grid"
						[paginator]="true"
						[rows]="8"
						[loading]="loading"
						emptyMessage="Aucune offre n'est disponible ou aucune offre ne correspond aux critères de recherche">
				<ng-template let-offre pTemplate="listItem">
					<div>
						{{offre.label}}
					</div>
				</ng-template>

				<ng-template let-offre pTemplate="gridItem">
					<a href="detail-offre/{{ offre.reference }}">
						<div class="offre-item col-12 md:col-6 lg:col-3 mt-5">
							<div class="surface-card surface-offre shadow-2 p-3 border-round">
								<div class="flex justify-content-between mb-3">
									<div class="row">
										<span class="block text-400 text-center mb-3">{{offre.titre}}</span>
										<span class="block font-bold text-900 text-center mb-3">{{offre.entreprise.designation}}</span>
										<div class="offre-image">
											<img class="image" src="assets/img/logo.png" alt="logo" height="85"/>
										</div>
									</div>
								</div>
								<div class="offre-icone">
									<div class="row">
										<div class="col-2">
											<em class="fa fa-clipboard text-blue-500 text-xl"></em>
										</div>
										<div class="col-10 text-right text-900">
											{{offre.typeContrat.designation}}
										</div>
									</div>
									<div class="row">
										<div class="col-2">
											<em class="fa fa-map-marker text-blue-500 text-xl"></em>
										</div>
										<div class="col-10 text-right text-900">
											{{offre.localisation}}
										</div>
									</div>
									<div class="row">
										<div class="col-2">
											<em class="fa fa-calendar text-blue-500 text-xl"></em>
										</div>
										<div class="col-10 text-right text-900">
											{{offre.dateExpiration | date: 'dd/MM/yyyy'}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</ng-template>
			</p-dataView>
		</div>
	</div>
</div>
<app-footer></app-footer>
