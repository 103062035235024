import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-saisie',
	templateUrl: './saisie.component.html',
	styleUrls: ['./saisie.component.scss']
})
export class SaisieComponent implements OnInit {

	@Input() critere: any;
	@Input() valeurSaisie: string;
	@Output() detailCandidatureChange: EventEmitter<any> = new EventEmitter<any>();
	@Input() submitted: boolean;

	constructor() {
	}

	ngOnInit(): void {
	}

	notifierChangement($event: any): void {
		this.detailCandidatureChange.emit($event.target.value);
	}
}
