<div [ngClass]="responsive" id="myTopnav">
	<a href="/">
		<img src="assets/img/logo.png" alt="logo" width="100"/>
	</a>
	<div class="menubar-right">
		<a class="mr-1" href="/accueil" *ngIf="!isAccueil()">
			<em class="home fa fa-home"></em>
		</a>
		<a class="mt-1" *ngIf="!isIdentification()" (click)="goToInscriptionOuEspaceCandidat()">
			<em class="mr-1 fa fa-user-circle"></em>
			{{ !isUtilisateurConnecte() ? 'Inscription' : 'Espace candidat' }}
		</a>
		<a class="mt-1" *ngIf="isUtilisateurConnecte() || (!isUtilisateurConnecte() && !isIdentification())" (click)="goToConnexionOuAccueil()"><em class="mr-1 fa" [ngClass]="{'fa-sign-in': !isUtilisateurConnecte(), 'fa-sign-out': isUtilisateurConnecte()}"></em>
			{{ !isUtilisateurConnecte() ? 'Connexion' : 'Déconnexion' }}</a>
		<a class="mt-1" href="http://vps10010.winihost.com:8080/recrutement-validation/login.jsp"
		   target="_blank" (click)="afficheResponsive();">
			<em class="fa fa-external-link mr-1"></em>
			Entreprise
		</a>
		<a class="icon mt-1" (click)="afficheResponsive()">
			<em class="fa fa-bars"></em>
		</a>
	</div>
</div>
<div>
	<router-outlet></router-outlet>
</div>

