import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { OffreEmploiService } from 'src/app/services/offre-emploi.service';
import { MetierService } from 'src/app/services/metier.service';
import { TypeContratService } from 'src/app/services/type-contrat.service';
import {finalize} from "rxjs/operators";

@Component({
	selector: 'app-accueil',
	templateUrl: './accueil.component.html',
	styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit {
	offres: any[];
	metiers: any[];
	metierSelectionnes: any[];
	typeContrats: any[];
	filtreVille: string;
	filtreEmploi: string;
	typeContratSelectionnes: any[];
	loading: boolean;

  constructor(private navigationService: NavigationService,
              private offreEmploiService: OffreEmploiService,
              private metierService: MetierService,
              private typeContratService: TypeContratService) {
  }

	ngOnInit(): void {
		this.listerOffres();
		this.listerMetier();
		this.listerTypeContrat();
	}

	goToDetailOffre(id: number): void {
		this.navigationService.goTo('detail-offre/' + id.toString());
	}

  listerOffres(): void {
    this.loading = true;
    this.offreEmploiService.lister()
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe({
        next: (data) => this.offres = data
      });
  }

	listerMetier(): void {
		this.metierService.lister().subscribe({
			next: (data) => this.metiers = data
		});
	}

	listerTypeContrat(): void {
		this.typeContratService.lister().subscribe({
			next: (data) => this.typeContrats = data
		});
	}

  filtrerOffres(): any[] {
    let offreFiltrees = this.offres;

    if (this.typeContratSelectionnes?.length) {
      offreFiltrees = offreFiltrees?.filter(
        offre => this.typeContratSelectionnes.map(typeContrat => typeContrat.designation)
          .includes(offre.typeContrat.designation));
    }

    if (this.filtreEmploi?.length) {
      offreFiltrees = offreFiltrees?.filter(
        offre => offre.titre.toString().toLowerCase().includes(this.filtreEmploi.toLowerCase()));
    }

    if (this.filtreVille?.length) {
      offreFiltrees = offreFiltrees?.filter(
        offre => offre.localisation.toString().toLowerCase().includes(this.filtreVille.toLowerCase()));
    }

    return offreFiltrees;
  }
}
