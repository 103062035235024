import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-selection-multiple',
	templateUrl: './selection-multiple.component.html',
	styleUrls: ['./selection-multiple.component.scss']
})
export class SelectionMultipleComponent implements OnInit {

	@Input() critere: any;
	@Input() valeurSelectionne: string;
	@Output() detailCandidatureChange: EventEmitter<any> = new EventEmitter<any>();
	@Input() submitted: boolean;

	valeurCritereSelectionnes: any;

	constructor() {
	}

	ngOnInit(): void {
		this.valeurCritereSelectionnes = this.critere.valeurCriteres?.filter(valeurCritere => this.valeurSelectionne.split(', ').includes(valeurCritere.valeur));
	}

	notifierChangement($event: any): void {
		this.detailCandidatureChange.emit($event.value.map(option => option.valeur).join(', '));
	}
}
