import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { AccueilModule } from 'src/app/modules/accueil/accueil.module';
import { IdentificationModule } from 'src/app/modules/identification/identification.module';
import { CandidatureModule } from 'src/app/modules/candidature/candidature.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { SharedRecrutementModule } from './shared/component/shared-recrutement.module';
import { EspaceCandidatComponent } from './modules/espace-candidat/espace-candidat.component';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		MenubarModule,
		ButtonModule,
		SharedRecrutementModule,
		SharedModule,
		AccueilModule,
		IdentificationModule,
		CandidatureModule,
		SharedModule,
		HttpClientModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {
}
