import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {CandidatureComponent} from 'src/app/modules/candidature/candidature.component';
import {CandidatureRoutingModule} from 'src/app/modules/candidature/candidature-routing.module';
import {StepsModule} from 'primeng/steps';
import {SelectionMultipleComponent} from './criteres/selection-multiple/selection-multiple.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {SelectionBinaireComponent} from './criteres/selection-binaire/selection-binaire.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FormsModule} from '@angular/forms';
import {SelectionUniqueComponent} from './criteres/selection-unique/selection-unique.component';
import {DropdownModule} from 'primeng/dropdown';
import {SaisieComponent} from './criteres/saisie/saisie.component';
import {InputTextModule} from 'primeng/inputtext';
import {ToastModule} from 'primeng/toast';
import {ConfirmationService, MessageService} from 'primeng/api';
import {SharedRecrutementModule} from 'src/app/shared/component/shared-recrutement.module';
import {CvComponent} from './criteres/cv/cv.component';
import {PhotoComponent} from './criteres/photo/photo.component';
import {FileUploadModule} from 'primeng/fileupload';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SharedModule} from '../../shared/shared.module';
import {ConfirmDialogModule} from 'primeng/confirmdialog';


@NgModule({
	declarations: [
		CandidatureComponent,
		SelectionMultipleComponent,
		SelectionBinaireComponent,
		SelectionUniqueComponent,
		SaisieComponent,
		CvComponent,
		PhotoComponent
	],
	imports: [
		CommonModule,
		CandidatureRoutingModule,
		ButtonModule,
		StepsModule,
		MultiSelectModule,
		SelectButtonModule,
		FormsModule,
		DropdownModule,
		InputTextModule,
		ToastModule,
		SharedRecrutementModule,
		FileUploadModule,
		ProgressSpinnerModule,
		SharedModule,
		ConfirmDialogModule,
	],
	exports: [
		PhotoComponent,
		CvComponent
	],
	providers: [
		MessageService,
		ConfirmationService
	]
})
export class CandidatureModule {
}
