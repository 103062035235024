import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Candidat} from 'src/app/models/candidat.model';

@Injectable({
	providedIn: 'root'
})
export class CandidatService {

	private url = '/api/candidats';

	constructor(private http: HttpClient) {
	}

	static updateUtilisateurConnecte(candidat: Candidat): void {
		localStorage.removeItem('utilisateurConnecte');
		localStorage.setItem('utilisateurConnecte', JSON.stringify(candidat));
	}

	static getCandidatConnecte(): Candidat {
		return JSON.parse(localStorage.getItem('utilisateurConnecte'));
	}

	creer(candidat: Candidat): Observable<void> {
		return this.http.put<void>(`${this.url}`, candidat);
	}

	modifier(candidat: Candidat): Observable<Candidat> {
		return this.http.put<Candidat>(this.url + '/modifier', candidat);
	}

	activer(id: string): Observable<void> {
		return this.http.put<void>([this.url, id, 'activer'].join('/'), {});
	}

	rechercher(email: string, password: string): Observable<Candidat> {
		const params = new HttpParams()
			.append('email', email)
			.append('password', password);
		return this.http.get<Candidat>([this.url, 'connexion'].join('/'), {params: params});
	}

	uploadPhoto(id: number, file: File): Observable<Candidat> {
		const formData: FormData = new FormData();
		formData.append('photo', file);
		return this.http.post<Candidat>(this.url + '/upload-photo?idCandidat=' + id, formData);
	}

	uploadCv(id: number, file: File): Observable<Candidat> {
		const formData: FormData = new FormData();
		formData.append('cv', file);
		return this.http.post<Candidat>(this.url + '/upload-cv?idCandidat=' + id, formData);
	}

	deletePhoto(id: number): Observable<Candidat> {
		return this.http.delete<Candidat>(this.url + '/delete-photo?idCandidat=' + id);
	}

	deleteCv(id: number): Observable<Candidat> {
		return this.http.delete<Candidat>(this.url + '/delete-cv?idCandidat=' + id);
	}
}
